import {
  Box,
  Container,
  Divider,
  Link,
  Typography
} from '@mui/material';
import React from 'react';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#000080',
        color: 'white',
        py: 3,
        mt: 'auto',
        width: '100%'
      }}
    >
      <Container maxWidth="lg">
        <Typography 
          variant="h6" 
          align="center" 
          sx={{ 
            textDecoration: 'underline', 
            fontWeight: 'bold', 
            mb: 2 
          }}
        >
          Call us on 
        </Typography>
        
        
        <Typography 
          variant="body1" 
          align="center" 
          sx={{ mb: 2 }}
        >
          +27 (0) 11 100 7645
        </Typography>
        <Divider sx={{ borderBottomWidth: '1px', borderColor: 'white', mb: 2 }} />
        <Typography 
          variant="body2" 
          align="center"
        >
          © 2025 Tiro Engineering and Projects (Pty) Ltd | All rights reserved | 
          powered by{' '}
          <Link 
            href="https://www.linkedin.com/in/zakhele-hakonze/" 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{ 
              color: 'white', 
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            zdharts
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;