import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CTAButton from './CTAButton';



const s1  = '/images/carousel/s1.webp';
const s2  = '/images/carousel/s2.jpg';
const s3  = '/images/carousel/s3.webp';
const s4  = '/images/carousel/s4.webp';
const s5  = '/images/carousel/s5.webp';
// Styled components
// Styled components
const ScrollButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '2rem',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer',
  animation: 'bounce 2s infinite',
  color: 'white',
  zIndex: 10,
  
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateX(-50%) translateY(0)',
    },
    '40%': {
      transform: 'translateX(-50%) translateY(-15px)',
    },
    '60%': {
      transform: 'translateX(-50%) translateY(-7px)',
    },
  },
}));

const CircleIcon = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  border: '2px solid white',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '8px',
  
  '&::after': {
    content: '"↓"',
    fontSize: '24px',
    fontWeight: 'bold',
  }
}));

const CarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden'
  //marginBottom: '-180px', // Adjust this value to control overlap (30% of 600px is 180px)

}));

const SlideContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

const CarouselImage = styled('img')(({ theme }) => ({
  width: '100vw',
  height: '100%',
  objectFit: 'cover',
}));

const CaptionContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  width: '80%',
  padding: theme.spacing(3),
  background: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1, // Ensure caption is above other elements
  [theme.breakpoints.down('sm')]: {
    top: 'auto', // Remove top positioning on mobile
    bottom: '20%', // Position from bottom on mobile
    transform: 'none',
    width: '100%',
    padding: theme.spacing(2),
  }
}));

//const NavigationButton = styled(IconButton)(({ theme, position }) => ({
//  position: 'absolute',
//  top: '50%',
//  transform: 'translateY(-50%)',
//  zIndex: 2,
//  color: 'white',
//  backgroundColor: 'rgba(0, 0, 0, 0.3)',
//  ...(position === 'left' ? { left: theme.spacing(2) } : { right: theme.spacing(2) }),
//  '&:hover': {
//    backgroundColor: 'rgba(0, 0, 0, 0.5)',
//  },
//}));

//const VerticalDivider = styled(Divider)(({ theme }) => ({
//  borderColor: 'white',
//  height: '80%', // Adjust height as needed
//  borderLeftWidth: '2px',
//  position: 'absolute',
//  top: '50%',
//  left: '50%',
//  transform: 'translate(-50%, -50%)',
//}));

const StyledButton = styled(Button)(({ theme, clicked }) => ({
  color: clicked ? 'white' : 'gray',
  borderColor: clicked ? 'gray' : 'white',
  '&:hover': {
    borderColor: 'white',
    color: 'white',
  },
}));

const Carousel = () => {
  const carouselContainerRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [clicked, setClicked] = useState(false);

  const slides = [
    {
      image: s1,
      title: 'Engineering Excellence in High-Voltage Substation Solutions Across Africa',
      description: '',
    },
    {
      image: s2,
      title: '10 Years of Safe, Innovative, and Cost-Effective Turnkey Projects',
      description: '',
    },
    {
      image: s3,
      title: 'Powering Africa’s Future Through Quality & Precision',
      description: '',
    },
    {
      image: s4,
      title: '100% Black-Owned. 100% Committed to African Energy Excellence',
      description: '',
    },
    {
      image: s5,
      title: 'From Concept to Commissioning: Your Trusted EPC Partner',
      description: "",
    },
  ];
  
  const handleScrollDown = () => {
    const nextSection = document.getElementById('Our Services'); // Make sure to add this ID to your next section
    if (nextSection) {
      nextSection.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start' // Align to top of section
      });
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => (prevStep + 1) % slides.length);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep - 1 + slides.length) % slides.length);
  };

  const handleClick = () => {
    setClicked(true);
  };

  useEffect(() => {
    if (carouselContainerRef.current) {
      carouselContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const autoPlay = setInterval(handleNext, 5000);
    return () => clearInterval(autoPlay);
  }, []);

  return (
    <Container maxWidth={false} sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100vw',
      padding: 0,
      position: 'relative', // Add relative positioning
      zIndex: 0, // Lower z-index than caption
    }}>
      <CarouselContainer>
        <SlideContainer>
          <CarouselImage
            src={slides[activeStep].image}
            alt={slides[activeStep].title}
          />
          <CaptionContainer>
            <Typography 
              variant="h4" 
              component="h3" 
              gutterBottom 
              sx={{
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.5rem', // Smaller font on mobile
                  sm: '2rem',
                  md: '2.5rem'
                }
              }}
            >
              {slides[activeStep].title}
            </Typography>
            <Divider
              sx={{
                borderColor: 'white',
                width: '30%',
                borderBottomWidth: '2px',
                borderRadius: 0,
                my: 2,
              }}
            />
            <Box display="flex" justifyContent="flex-start" sx={{ mt: 2 }}>
              <CTAButton variant="primary" onClick={() => console.log('clicked')}>
                <Link to="/Projects" style={{ textDecoration: 'none', color: 'white' }}>
                  View Projects
                </Link>
              </CTAButton>
            </Box>
          </CaptionContainer>
          <ScrollButton onClick={handleScrollDown}>
            <CircleIcon />
            <Typography variant="caption" sx={{ fontSize: '0.875rem' }}>
              Scroll Down
            </Typography>
          </ScrollButton>
        </SlideContainer>
      </CarouselContainer>
    </Container>
  );
};

export default Carousel;