import {
  Box,
  Chip,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

// Mock data - Replace with actual data from PDF parsing
const projects = [
    {
      "client": "South 32",
      "contractDescription": "Main surface substation upgrade at wessels mine",
      "startDate": "17-Oct-21",
      "completionDate": "30-Apr-22",
      "status": "100%",
      "contactPerson": "Mr. Tshepo Magoru",
      "contactNumber": "053 742 2285/0736478057",
      "value": "R21,440,585.64"
    },
    {
      "client": "PSD Energy",
      "contractDescription": "Building works at two rivers platinum mine",
      "startDate": "25-Jan-21",
      "completionDate": "1-Jun-22",
      "status": "100%",
      "contactPerson": "Mr Andreas Richelmann",
      "contactNumber": "082 850 9023",
      "value": "R4,787,928.97"
    },
    {
      "client": "National Research Foundation",
      "contractDescription": "Replacement supply delivery installation and commissioning of 11kv 500kva transformer 2 11kv switch and 11kv power cable",
      "startDate": "2-Dec-21",
      "completionDate": "2-Jun-22",
      "status": "100%",
      "contactPerson": "Mr. Petie Roos",
      "contactNumber": "076 778 6670",
      "value": "R1,755,067.02"
    },
    {
      "client": "Consolidated Power Projects",
      "contractDescription": "Sefokobolea Substation Upgrade",
      "startDate": "29-Nov-16",
      "completionDate": "30-Nov-17",
      "status": "100%"
    },
    {
      "client": "Komatsu Mining Corp",
      "contractDescription": "Erection Works",
      "startDate": "15-May-18",
      "completionDate": "31-Oct-18",
      "status": "100%"
    },
    {
      "client": "Power System Design Energy",
      "contractDescription": "Electrical Substation Installation and Construction at Sefateng Mine",
      "startDate": "1-Feb-21",
      "completionDate": "31-May-21",
      "status": "100%"
    },
    {
      "client": "Ingweguard",
      "contractDescription": "Extra Man Power for the execution of trenches",
      "startDate": "21-Oct-20",
      "completionDate": "30-Nov-20",
      "status": "100%"
    },
    {
      "client": "Siyavuya Power Projects",
      "contractDescription": "Construction of the TFS and CT yard Platforms at Garona Loop 16 Substation",
      "startDate": "6-Aug-20",
      "completionDate": "31-Mar-21",
      "status": "100%"
    },
    {
      "client": "Actom Power Systems",
      "contractDescription": "Soshanguve 132/11kV Substation",
      "startDate": "1-Feb-20",
      "completionDate": "30-Jun-22",
      "status": "100%"
    },
    {
      "client": "Lesotho Electricity Company",
      "contractDescription": "Installation of 5MVA Transformer, Motorised Isolator, 66kV Circuit Breaker, 66kV Control Panel and 11kV CT's.Testing and Commissioning of Transformer",
      "startDate": "25-Jan-18",
      "completionDate": "31-Aug-19",
      "status": "100%"
    },
    {
      "client": "Rheinmetall",
      "contractDescription": "Upgrade 11000 Volts feeder breaker and supply new 1500 kVa transformer",
      "startDate": "15-May-18",
      "completionDate": "31-Oct-18",
      "status": "100%"
    },
    {
      "client": "Actom Power Systems",
      "contractDescription": "Hartebeesfontein 88/11kV Substation - Installation of 88kV High voltage equipment, control cable, power cable and electrical protection panels",
      "startDate": "3-Jul-17",
      "completionDate": "30-Oct-17",
      "status": "100%"
    },
    {
      "client": "Actom Power Systems",
      "contractDescription": "88/11kV Gibela substation :Installation of earthgrid,steelwork, 88kV Substation equipment, aluminium conductor, clamps and secondary equipment",
      "startDate": "17-Feb-17",
      "completionDate": "30-Nov-17",
      "status": "100%"
    },
    {
      "client": "South African National Blood Services",
      "contractDescription": "Supply of 185x4c CU PVC SWA PVC 600/1000V SABS 1507",
      "startDate": "29-Mar-17",
      "completionDate": "6-Apr-17",
      "status": "100%"
    },
    {
      "client": "Consolidated Power Projects",
      "contractDescription": "Heriotdale Substation -Installation of 6.6kV Switchgear, 88kV Protection panel, steelwork.control cable",
      "startDate": "29-Nov-16",
      "completionDate": "30-Jun-17",
      "status": "100%"
    },
    {
      "client": "Mbeu Engineering",
      "contractDescription": "Substation work at Eskom Allandale substation:Construction of civil foundations, cable trenches, installation of high voltage equipment, clamps and conductors",
      "startDate": "7-Dec-16",
      "completionDate": "30-Oct-17",
      "status": "100%"
    },
    {
      "client": "Consolidated Power Projects",
      "contractDescription": "Sefokobola 88/11kV Substation - Construction of equipment foundation, supply and installation of substation steelwork, clamps and control cable,Installation of 88kV High Voltage equipment, 132kV protection, installation and termination of power cable",
      "startDate": "29-Nov-16",
      "completionDate": "30-Jun-17",
      "status": "100%"
    },
    {
      "client": "South African National Blood Services",
      "contractDescription": "Supply of flourescent Tubes",
      "startDate": "27-Nov-16",
      "completionDate": "30-Nov-17",
      "status": "100%"
    },
    {
      "client": "Actom Power Systems",
      "contractDescription": "Hartebeesfontein 88/11kV Substation - Construction of equipment foundations, Transformer firewall, Oil dam, removing old fence, installation of clearvu fence, modification of trenches",
      "startDate": "7-Nov-16",
      "completionDate": "30-Oct-17",
      "status": "100%"
    },
    {
      "client": "Erkurhuleni Municipality",
      "contractDescription": "Upgrade of SANBS building electrical swithgear including, supply, installation, testing and commissioning of 2 X 1 MVA Transformers, 1x LV Switchgear, 1 x 11kV Ring main unit, control cables, power cables and modificaation of the building",
      "startDate": "30-Sep-16",
      "completionDate": "30-Nov-16",
      "status": "100%"
    },
    {
      "client": "Actom Power Systems",
      "contractDescription": "Contruction of equipment foundations, installation of High voltage eletrical equipment, steelwork,substation clamps, aluminium conductors protection",
      "startDate": "21-Jul-16",
      "completionDate": "30-Jun-17",
      "status": "100%"
    },
    {
      "client": "South African National Blood Services",
      "contractDescription": "Upgrade of SANBS building electrical swithgear including, supply, installation, testing and commissioning of 2 X 1 MVA Transformers, 1x LV",
      "startDate": "26-Apr-16",
      "completionDate": "30-Nov-17",
      "status": "100%"
    },
    {
      "client": "Actom MV Switchgear",
      "contractDescription": "Supply and install Kwena earth cable At Atom Road Substation",
      "startDate": "21-Apr-16",
      "completionDate": "30-Jun-16",
      "status": "100%"
    },
    {
      "client": "African Electrical Consultants",
      "contractDescription": "Reconstruction of OHTE line Mookgophong",
      "startDate": "11-Apr-15",
      "completionDate": "15-Apr-16",
      "status": "100%"
    },
    {
      "client": "Erkurhuleni Municipality",
      "contractDescription": "Remove and install the existing 33kV Circuit Breaker from existing bay to a new bay and remove tap changer from transformer 4 to transformer number 2 at Boksburg step down substation",
      "startDate": "2-Dec-15",
      "completionDate": "28-Feb-16",
      "status": "100%"
    },
    {
      "client": "MEV Energy system",
      "contractDescription": "Supply and installation of substation steelwork, labels, clamps, aluminium conductor,Aluminium busbars, control cables and installation of High Voltage primary equipment, 132kV protection panel and all associated secondary equipment.(IPP step -up station)",
      "startDate": "8-Jul-15",
      "completionDate": "4-Feb-16",
      "status": "100%"
    },
    {
      "client": "MEV Energy system",
      "contractDescription": "Supply and installation of substation steelwork, labels, clamps, aluminium conductor,Aluminium busbars, control cables and installation of High Voltage primary equipment, 132kV protection panel and all associated secondary equipment.(Eskom Switching station)",
      "startDate": "8-Jul-15",
      "completionDate": "12-Jan-16",
      "status": "100%"
    },
    {
      "client": "City Power",
      "contractDescription": "Supply of Siemens relays comms cable",
      "startDate": "16-Apr-15",
      "completionDate": "29-May-15",
      "status": "100%"
    },
    {
      "client": "City Power",
      "contractDescription": "Supply of coupler: Earth Rod, 16 x 80mm, bronze",
      "startDate": "5-Mar-15",
      "completionDate": "18-Mar-15",
      "status": "100%"
    }
];

const ProjectsTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Date formatting function
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Container sx={{ padding: 3, py: 4 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ 
        mb: 4,
        color: '#001f87',
        fontWeight: 'bold',
        fontSize: isMobile ? '1.5rem' : '2rem'
      }}>
        Latest Projects
      </Typography>

      {isMobile ? (
        // Mobile Card View
        <Box sx={{ display: 'grid', gap: 2 }}>
          {projects.map((project, index) => (
            <Paper 
              key={index} 
              sx={{ 
                p: 2,
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.2s',
                '&:hover': { transform: 'translateY(-2px)' }
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Chip 
                  label={project.status}
                  size="small"
                  sx={{ 
                    backgroundColor: project.status === 'Completed' ? '#4caf50' : '#CD9B1D',
                    color: 'white',
                    fontWeight: 'bold'
                  }}
                />
                <Typography variant="caption" color="text.secondary">
                  {formatDate(project.completionDate)}
                </Typography>
              </Box>

              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: "bold",
                  color: '#001f87',
                  mb: 1
                }}
              >
                {project.client}
              </Typography>

              <Typography 
                variant="body2" 
                sx={{
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  mb: 1
                }}
              >
                {project.contractDescription}
              </Typography>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                color: 'text.secondary',
                fontSize: '0.8rem'
              }}>
                <span>Start: {formatDate(project.startDate)}</span>
                <span>End: {formatDate(project.completionDate)}</span>
              </Box>
            </Paper>
          ))}
        </Box>
      ) : (
        // Desktop Table View
        <TableContainer 
          component={Paper}
          sx={{ 
            borderRadius: 2,
            boxShadow: 3,
            '& .MuiTable-root': { minWidth: 800 }
          }}
        >
          <Table aria-label="projects table">
            <TableHead>
              <TableRow sx={{ 
                backgroundColor: '#001f87', 
                '& .MuiTableCell-head': { 
                  color: theme.palette.common.white,
                  fontSize: '1rem'
                }
              }}>
                <TableCell sx={{ fontWeight: "bold" }}>Client</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Contract Description</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Start Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Completion Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }} align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, index) => (
                <TableRow 
                  key={index}
                  hover 
                  sx={{ 
                    '&:nth-of-type(even)': { backgroundColor: '#f5f5f5' },
                    '&:last-child td': { borderBottom: 0 }
                  }}
                >
                  <TableCell sx={{ fontWeight: 'medium' }}>{project.client}</TableCell>
                  <TableCell>{project.contractDescription}</TableCell>
                  <TableCell align="center">{formatDate(project.startDate)}</TableCell>
                  <TableCell align="center">{formatDate(project.completionDate)}</TableCell>
                  <TableCell align="center">
                    <Chip 
                      label={project.status}
                      sx={{ 
                        backgroundColor: project.status === 'Completed' ? '#4caf50' : '#CD9B1D',
                        color: 'white',
                        fontWeight: 'bold',
                        minWidth: 100
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default ProjectsTable;