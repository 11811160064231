import { useEffect, useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../images/logo/logo2.png';

// Styled components for the navbar
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  background-color: rgba(0, 0, 0, 0.2); /* Make it transparent */
  color: #030671;
  position: fixed; /* Fix it at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above everything, including the carousel */
  transition: background-color 0.3s ease-in-out;

  /* Optional: Add a slight background when scrolling */
  &.scrolled {
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    
    // Force all child elements to inherit color
    * {
      color: inherit !important;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

   a {
    margin: 0 1rem;
    color: #000080; /* Dark blue */
    text-decoration: none;
    font-weight: bold; /* Make text bold */
    font-size: 1.1rem; /* Increase font size */
    padding: 10px 20px; /* Add padding for better click area */
    border-radius: 20px; /* Pill shape */
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #000080; /* Blue background */
      color: white;
      text-decoration: none; /* Remove underline */
    }
  }

  @media only screen and (max-width: 1024px) {
    position: fixed;
    z-index: 50;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: rgba(3, 7, 113, 0.59);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(25px);
    transition: 1s;

    &.responsive_nav {
      transform: translateY(100vh);
    }

    a {
      font-size: 1.5rem;
      color: white;
    }
  }
`;

const NavButton = styled.button`
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #030671;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;

  &.nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: white;
  }

  @media only screen and (max-width: 1024px) {
    visibility: visible;
    opacity: 1;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 5px;
  left: 15px;
  font-size: 1.2rem;
  color: black;
  z-index: 20;
  width: 214.5px;
  height: 71px;
`;

function Navbar() {
  const navRef = useRef();
  const [scrolled, setScrolled] = useState(false);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Header className={scrolled ? "scrolled" : ""}>
      <h3>
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>
      </h3>
      <Nav ref={navRef}>
        <Link to="/" onClick={showNavbar}>Home</Link>
        <Link to="/about" onClick={showNavbar}>About us</Link>
        <Link to="/projects" onClick={showNavbar}>Projects</Link>
        <Link to="/contact" onClick={showNavbar}>Contact us</Link>
        <NavButton className="nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </NavButton>
      </Nav>
      <NavButton onClick={showNavbar}>
        <FaBars />
      </NavButton>
    </Header>
  );
}

export default Navbar;