import Divider from '@mui/material/Divider';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import 'react-tabs/style/react-tabs.css';
import '../../styles.css'; // make sure to create this file in your project directory
import AnimatedPage from '../UI/AnimatedPage';
import Footer from '../UI/footer';



const Project5 = () => {
  const carouselContainerRef = useRef(null);
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  };
const navigate = useNavigate(); // Create a navigate function 

  
const handleBackButtonClick = () => {
  navigate('/services'); // Redirect to 'ourservices' page
};
  return (
    <AnimatedPage>
    <div className="homebackmain2">
      <div className="homeback2" >
      
        <h1 className="center-textC">Project 5</h1>
        <Divider sx={{ borderBottomWidth: '3px' }} variant="middle" />
        <br/>
          <p className="center-text">
            <span class="loader" style={{paddingTop:'100px', marginBottom:'50px', color:'white'}}>UNDER CONSTRUCTION...</span>
          </p>
      </div>
      <Footer/>
    </div>
    </AnimatedPage>
  );
};

export default Project5;
