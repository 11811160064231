import { Box, Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

const AutoPlayYouTube = ({ videoId }) => {
  const [isInView, setIsInView] = useState(false);
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Load the YouTube IFrame API
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // Initialize Intersection Observer
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        threshold: 0.5, // Trigger when 50% of the component is visible
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    // Setup YouTube Player when API is ready
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('youtube-player', {
        videoId: videoId,
        playerVars: {
          autoplay: 0,
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          loop: 1,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          showinfo: 0,
          mute: 1, // Muted by default for autoplay
          playlist: videoId // Required for looping
        },
        events: {
          onReady: (event) => {
            // Player is ready
            if (isInView) {
              event.target.playVideo();
            }
          },
        },
      });
    };

    return () => {
      observer.disconnect();
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]);

  // Handle visibility changes
  useEffect(() => {
    if (playerRef.current && playerRef.current.playVideo) {
      if (isInView) {
        playerRef.current.playVideo();
      } else {
        playerRef.current.pauseVideo();
      }
    }
  }, [isInView]);

  return (
    <Box
      ref={videoRef}
      sx={{
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          paddingTop: '56.25%', // 16:9 Aspect Ratio
          backgroundColor: '#000',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: 'none', // Prevent interaction with the video
            '& iframe': {
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              border: 'none',
            },
          }}
        >
          <div id="youtube-player" />
        </Box>
      </Paper>
    </Box>
  );
};

export default AutoPlayYouTube;


/*

Example usage

    <div>
      <AutoPlayYouTube videoId="dQw4w9WgXcQ" /> 
    </div>
*/