import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import CTAButton from './CTAButton';

const TextImageSection = ({ 
  title, 
  description, 
  imageSrc, 
  imageAlt,
  backgroundColor = '#ffffff'
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ 
      py: { xs: 4, md: 8 },
      backgroundColor: '#F5F5F5',
      position: 'relative', // Needed for pseudo-element positioning
      minHeight: '500px', // Set minimum height (adjust as needed)
      '&::before': { // Pseudo-element for background
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/images/pack/dot2.PNG)',
        backgroundSize: 'cover', // or 'contain' depending on your needs
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0.3, // Adjust opacity here (0 = transparent, 1 = opaque)
        zIndex: 1,
      },
    }}>
      <Container maxWidth="50%" sx={{ 
                position: 'relative', // Important for z-index stacking
                zIndex: 2 // Ensures content stays above background
        }}>
        <Grid 
          container 
          spacing={{ xs: 4, md: 6 }} 
          alignItems="center"
        >
          {/* Text Content */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: { xs: 2, md: 4 } }}>
              <Typography 
                variant="h3" 
                component="h2" 
                gutterBottom
                sx={{ 
                   textAlign: 'center',
                  color:"#000080",
                  fontWeight: 'bold',
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  mb: 1
                }}
              >
                {title}
              </Typography>
              <Typography 
                variant="body1"
                sx={{
                  color:"text.secondary", 
                  fontSize: { xs: '1rem', md: '1.1rem' },
                  lineHeight: 1.8,
                  color: '#4A5568'
                }}
              >
                {description}
              </Typography>
            </Box>
          </Grid>
          {/* Image */}
          <Grid item xs={12} md={6} order={isMobile ? 1 : 2}>
            <Box
              component="img"
              src={imageSrc}
              alt={imageAlt}
              sx={{
                width: '100%',
                height: 'auto',
                maxHeight: 500,
                objectFit: 'cover',
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '8px' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        mt: 4,
        position: 'relative',
        zIndex: 2
      }}>
        <Link 
          to="/Projects" 
          style={{ 
            textDecoration: 'none',
            display: 'inline-block'
          }}
        >
          <CTAButton variant="primary">View Projects</CTAButton>
        </Link>
      </Box>
      </div>
    </Box>
  );
};

export default TextImageSection;