import styled from '@emotion/styled';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimatedPage from './AnimatedPage';

const SliderContainer = styled.div`
  height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
  overflow: hidden;
`;

const SliderTrack = styled.div`
  display: flex;
  width: calc(250px * 20);
  animation: scroll 60s linear infinite;

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-250px * 10));
    }
  }

  &:hover {
    animation-play-state: paused;
  }
`;

const Slide = styled.div`
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;

  img {
    width: 100%;
    transition: transform 1s;
  }

  img:hover {
    transform: translateZ(20px);
  }
`;

const Testimonials = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  };

  const handleBackButtonClick = () => {
    navigate('/Projects');
  };

  return (
    <AnimatedPage>
      <Box 
        sx={{ 
          py: 0,
          textAlign: 'center',
          backgroundColor: '#F5F5F5',
        }}
      />
      <Typography 
        variant="h3" 
        component="h2" 
        sx={{ 
          mb: 1,
          fontWeight: 'bold',
          fontSize: isMobile ? '2rem' : '2.5rem',
          color: '#000080',
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
        }}
      >
        Our Clients
      </Typography>
      <SliderContainer>
        <SliderTrack>
          {[...Array(2)].map((_, iteration) => (
            <React.Fragment key={iteration}>
              <Slide>
                <img src={'/images/comLogo/Rheinmetall.webp'} alt="Rheinmetall" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/Actom.webp'} alt="Actom" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/AEC.webp'} alt="AEC" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/South32.webp'} alt="South32" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/Conco Power Maintenace.webp'} alt="Conco Power Maintenace" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/Lesotho Electricity Company.webp'} alt="Lesotho Electricity Company" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/psd Energy.webp'} alt="psd Energy" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/National Research Foundation.webp'} alt="National Research Foundation" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/sanbs.webp'} alt="South Africa National Blood Service" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/siyavuya.webp'} alt="siyavuya" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/Tractionel Holdings.webp'} alt="Tractionel Holdings" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/City Power Johannesburg.webp'} alt="City Power Johannesburg" />
              </Slide>
              <Slide>
                <img src={'/images/comLogo/Ekurhuleni Municipality.webp'} alt="Ekurhuleni Municipality" />
              </Slide>
            </React.Fragment>
          ))}
        </SliderTrack>
      </SliderContainer>
    </AnimatedPage>
  );
};

export default Testimonials;