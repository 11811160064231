import React from 'react';
import { Helmet } from 'react-helmet';
import 'react-tabs/style/react-tabs.css';
import AnimatedPage from '../UI/AnimatedPage';
import TextGallerySection from '../UI/TextGallerySection';
import Footer from '../UI/footer';



const About = () => {
  const galleryImages = [
    { src: '/images/gallery/40MVA TRF Base.webp', alt: '40MVA TRF Base' },
    { src: '/images/gallery/66kV Stringing - Lesotho.webp', alt: '66kV Stringing' },
    { src: '/images/gallery/BTU Installation.webp', alt: 'BTU Installation' },
    { src: '/images/gallery/IMG_3431 2.webp', alt: 'Project Image 4' },
    { src: '/images/gallery/SHLL.webp', alt: 'Project Image 5' },
    { src: '/images/gallery/TRNCHNG.webp', alt: 'TRNCHNG' },
  ];
  return (
    <AnimatedPage>
      <Helmet>
        <title>Tiro Engineering - About Us</title>
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2px' }}>
        <TextGallerySection
          title="Who We Are"
          // You can pass description as a prop, or use the default formatted text
          // description="Your custom description here"
          images={galleryImages}
          backgroundColor="#F5F5F5"
        />
      </div>
      <Footer/>
    </AnimatedPage>
);
};
export default About;
