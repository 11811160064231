import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

// Brand colors
const brandColors = {
  primary: '#030671',    // Deep blue
  secondary: '#FFD700',  // Gold
  accent: '#007BFF'      // Electric blue
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: '#F5F5F5',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

const CoreValueItem = ({ text }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    mb: 1.5,
    padding: '4px',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#F9FAFC'
    }
  }}>
    <CheckCircleOutlineIcon sx={{ 
      color: brandColors.secondary, 
      mr: 1,
      fontSize: '1.5rem'
    }} />
    <Typography variant="body1" sx={{ fontWeight: 500 }}>{text}</Typography>
  </Box>
);

const MissionVisionSection = () => {
  const theme = useTheme();

  return (
    <Box sx={{ 
      py: 4, // Reduced padding
      px: 1,
      width: '100%',
      backgroundColor: '#000080',
      position: 'relative',
      zIndex: 2,
      mt: { 
        xs: 4, // Add margin-top on mobile
        sm: 2, 
        md: 0 
      },
      '& .MuiCard-root': {
        height: '100%',
        minHeight: {
          xs: 'auto', // Auto height on mobile
          sm: '250px'
        }
      }
      }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Mission Card */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{color: '#000080', fontWeight: 'bold', textAlign: 'center'  }}>
                OUR MISSION
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{textAlign: 'center' }}>
                Our mission is to become a center of <span style={{ fontWeight: 'bold' }}>engineering excellence</span> by constantly improving on our delivery times
                while maintaining the highest standard of quality and safety.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Vision Card */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#000080', fontWeight: 'bold', textAlign: 'center' }}>
                VISION
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{textAlign: 'center' }}>
                We aim to become the foremost electrical substation solutions organisation on the African continent. We
                exist exclusively to address the energy needs of the African continent by providing safe, innovative and
                cost-effective engineering solutions; we believe <span style={{ fontWeight: 'bold' }}>we exist to engineer excellence</span>.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Key Success Factor Card */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#000080', fontWeight: 'bold', textAlign: 'center'  }}>
                KEY SUCCESS FACTOR
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{textAlign: 'center' }}>
                Completing projects on time, within budget by adhering to highest safety and quality standards.
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Core Values Card */}
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: '#000080', fontWeight: 'bold', textAlign: 'center'  }}>
                CORE VALUES
              </Typography>
              <CoreValueItem text="Integrity"/>
              <CoreValueItem text="Diligence" />
              <CoreValueItem text="Accountability" />
              <CoreValueItem text="Respect" />
              <CoreValueItem text="Ethics" />
              <CoreValueItem text="Excellence" />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MissionVisionSection;