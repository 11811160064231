import React from 'react';
import { Helmet } from 'react-helmet';
import 'react-tabs/style/react-tabs.css';
import AnimatedPage from '../UI/AnimatedPage';
import ContactUs from '../UI/ContactUs';
import Footer from '../UI/footer';



const Contact = () => {
  return (
    <AnimatedPage>
      <Helmet>
        <title>Tiro Engineering - Contact Us</title>
      </Helmet>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2px' }}>
        <ContactUs />
      </div>
      <Footer/>
    </AnimatedPage>
);
};
export default Contact;
