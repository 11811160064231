import Divider from '@mui/material/Divider';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import About from './components/Pages/About';
import Contact from './components/Pages/contact';
import Projects from './components/Pages/Projects';
import Project1 from './components/Projects/project1';
import Project10 from './components/Projects/project10';
import Project2 from './components/Projects/project2';
import Project3 from './components/Projects/project3';
import Project4 from './components/Projects/project4';
import Project5 from './components/Projects/project5';
import Project6 from './components/Projects/project6';
import Project7 from './components/Projects/project7';
import Project8 from './components/Projects/project8';
import Project9 from './components/Projects/project9';
import Navbar from './components/UI/navbar';
import Packages from './components/UI/packages';
import ScrollToTop from './components/UI/ScrollToTop';
import Home from './home';
import './styles.css';




function App() {
  useEffect(() => {
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    }, []); 
  return (
    <Router>
        <ScrollToTop />
        <div className='go'>
        <React.Fragment>
          <Navbar/>
		    </React.Fragment>
        <Divider sx={{ borderBottomWidth: '1px', borderColor: 'black' }} variant="middle"/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/project1" element={<Project1 />} /> {/* Add the route for Barbershop */}
          <Route path="/project2" element={<Project2 />} /> {/* Add the route for Barbershop */}
          <Route path="/project3" element={<Project3 />} /> {/* Add the route for Barbershop */}
          <Route path="/project4" element={<Project4 />} /> {/* Add the route for Barbershop */}
          <Route path="/project5" element={<Project5 />} /> {/* Add the route for Barbershop */}
          <Route path="/project6" element={<Project6 />} /> {/* Add the route for Barbershop */}
          <Route path="/project7" element={<Project7 />} /> {/* Add the route for Barbershop */}
          <Route path="/project8" element={<Project8 />} /> {/* Add the route for Barbershop */}
          <Route path="/project9" element={<Project9 />} /> {/* Add the route for Barbershop */}
          <Route path="/project10" element={<Project10 />} /> {/* Add the route for Barbershop */}
          <Route path="/packages" element={<Packages />} /> {/* Add the route for Barbershop */}
        </Routes>
        </div>
    </Router>
  );
}

export default App;
