import { Box, Card, CardContent, CardMedia, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import AnimatedPages from "./AnimatedPage";

const packagesData = [
  { 
    title: "Project Management", 
    image: "/images/pack/Project Management.webp",
    description: "Expert project management services ensuring timely delivery and quality execution"
  },
  { 
    title: "Turnkey Substation Installation", 
    image: "/images/pack/TurnkeySubstation.webp",
    description: "Complete end-to-end substation installation and construction solutions"
  },
  { 
    title: "Overhead and Underground Reticulation", 
    image: "/images/pack/Overhead and Underground Reticulation.webp",
    description: "Comprehensive reticulation services for both overhead and underground systems"
  },
  { 
    title: "Construction Services", 
    image: "/images/pack/Construction Services.webp",
    description: "Professional construction services for electrical infrastructure projects"
  },
  { 
    title: "Lighting Installations", 
    image: "/images/pack/light.webp",
    description: "Expert installation of lighting systems for various applications"
  },
  { 
    title: "Electrical Maintenance", 
    image: "/images/pack/Electrical Maintenance.webp",
    description: "Regular maintenance and testing services to ensure optimal system performance"
  },
];

const Packages = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AnimatedPages>
      <Box sx={{ 
        py: 4, 
        backgroundColor: '#000080', 
        boxShadow: '5px 5px 5px rgba(0, 0, 128, 0.5)',
        position: 'relative', // Needed for pseudo-element positioning
        minHeight: '500px', // Set minimum height (adjust as needed)
        '&::before': { // Pseudo-element for background
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/images/pack/dot3.PNG)',
        backgroundSize: 'cover', // or 'contain' depending on your needs
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0.3, // Adjust opacity here (0 = transparent, 1 = opaque)
        zIndex: 1,
      }, 
      }}>
        <Typography 
          variant="h2" 
          component="h2" 
          align="center" 
          sx={{ 
            mb: 4,
            fontWeight: 'bold',
            fontSize: isMobile ? '2rem' : '2.5rem',
            color: 'white' // Navy blue to match your brand
          }}
        >
          Our Services
        </Typography>

        <Container maxWidth="lg" style={{ 
          position: 'relative', // Important for z-index stacking
          zIndex: 2 // Ensures content stays above background
          }}>
          <Grid 
            container 
            spacing={4} 
            justifyContent="center"
          >
            {packagesData.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card 
                  sx={{ 
                    height: "100%", 
                    display: "flex", 
                    flexDirection: "column",
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 8px 16px rgba(0,0,0,0.2)'
                    }
                  }}
                >
                  <CardMedia
                    component="img"
                    height={isMobile ? 200 : 240}
                    image={service.image}
                    alt={service.title}
                    sx={{
                      objectFit: 'cover',
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography 
                      variant="h5" 
                      component="h3" 
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        fontSize: isMobile ? '1.2rem' : '1.5rem',
                        color: '#000080' // Navy blue to match your brand
                      }}
                    >
                      {service.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        fontSize: isMobile ? '0.9rem' : '1rem'
                      }}
                    >
                      {service.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </AnimatedPages>
  );
};

export default Packages;