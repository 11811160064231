import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, IconButton, Tooltip, Zoom } from '@mui/material';
import React from 'react';

const WhatsAppButton = ({ phoneNumber = '1234567890', message = 'Hello! I have a question about your services.' }) => {
  const handleClick = () => {
    // Encode the message for URL
    const encodedMessage = encodeURIComponent(message);
    // Create WhatsApp URL
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    // Open in new tab
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        right: { xs: 16, md: 24 },
        bottom: { xs: 16, md: 24 },
        zIndex: 1000,
      }}
    >
      <Tooltip 
        title="Chat with us on WhatsApp"
        TransitionComponent={Zoom}
        arrow
      >
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: '#25D366',
            width: { xs: 56, md: 64 },
            height: { xs: 56, md: 64 },
            '&:hover': {
              backgroundColor: '#128C7E',
              transform: 'scale(1.1)',
            },
            transition: 'all 0.3s ease',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            animation: 'pulse 2s infinite',
            '@keyframes pulse': {
              '0%': {
                boxShadow: '0 0 0 0 rgba(37, 211, 102, 0.4)',
              },
              '70%': {
                boxShadow: '0 0 0 15px rgba(37, 211, 102, 0)',
              },
              '100%': {
                boxShadow: '0 0 0 0 rgba(37, 211, 102, 0)',
              },
            },
          }}
        >
          <WhatsAppIcon 
            sx={{ 
              color: 'white',
              fontSize: { xs: 32, md: 40 },
            }} 
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default WhatsAppButton;