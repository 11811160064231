import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import AutoPlayYouTube from './components/UI/AutoPlayYouTube';
import Carousel from './components/UI/Carousel';
import Footer from './components/UI/footer';
import MissionVisionSection from './components/UI/MissionVisionSection';
import Packages from './components/UI/packages';
import Testimonials from './components/UI/Testimonials';
import TextImageSection from './components/UI/TextImageSection';
import WhatsAppButton from './components/UI/WhatsAppButton';
import './index.css';
import './styles.css'; // make sure to create this file in your project directory





const Home = () => {  
  
  const carouselContainerRef = useRef(null);
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(true);
  };

  useEffect(() => {
    if (carouselContainerRef.current) {
      carouselContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  
  return (
    <div div style={{ width: '100%' }}>   
      <div div style={{ width: '100%' }}>
          <Carousel/>
          <Helmet>
            <title>Tiro Engineering - Home</title>
          </Helmet>
          
          <TextImageSection
            title="Who We Are"
            description={
              <>
                <Typography component="div" variant="body1" sx={{ mb: 2 }}>
                  <Box component="span" sx={{ 
                    fontWeight: 700, 
                    color: '#030671',
                    fontSize: '1.2rem'
                  }}>
                    Tiro Engineering and Projects (Pty) Ltd
                  </Box> 
                  {' '}is a leading <Box component="span" sx={{ fontWeight: 600 }}>turnkey Engineering Procurement and Construction (EPC)</Box> company specializing in high voltage substation projects.
                </Typography>

                <Typography variant="body1" sx={{ mb: 2 }}>
                  Founded in 2014 and headquartered in Johannesburg, we're expanding our footprint across 
                  <Box component="span" sx={{ fontWeight: 600 }}> Southern Africa</Box> with a focus on:
                </Typography>

                <Box component="ul" sx={{ 
                  pl: 3, 
                  mb: 2,
                  '& li': { 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1.5,
                    '& svg': { color: '#FFD700', mr: 1.5 }
                  }
                }}>
                  <li><CheckCircleOutlineIcon />Municipal infrastructure development</li>
                  <li><CheckCircleOutlineIcon />Mining sector electrification</li>
                  <li><CheckCircleOutlineIcon />Utility-scale energy solutions</li>
                </Box>

                <Typography variant="body1" sx={{ mb: 2 }}>
                  Our <Box component="span" sx={{ fontWeight: 700, color: '#030671' }}>10 years experience</Box> 
                  {' '}encompasses landmark projects for:
                </Typography>

                <Grid container spacing={1} sx={{ mb: 2, }}>
                  {['South32', 'Eskom', 'National Research Foundation', 'Lesotho Electricity Company'].map((client) => (
                    <Grid item xs={12} sm={6} key={client}>
                      <Box sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        bgcolor: '#F7FAFC',
                        p: 1,
                        borderRadius: 1,
                        boxShadow: '5px 5px 5px rgba(42, 42, 54, 0.1)'
                      }}>
                        <FiberManualRecordIcon sx={{ 
                          fontSize: '0.6rem', 
                          color: '#030671', 
                          mr: 1.5 
                        }}/>
                        {client}
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                <Typography variant="body1">
                  As a <Box component="span" sx={{ fontWeight: 700 }}>100% black-owned enterprise</Box>, we combine technical excellence with deep local knowledge to deliver <Box component="span" sx={{ color: '#030671' }}>African energy solutions for African challenges</Box>.
                  <Link 
                    to="/about" 
                    style={{ 
                      textDecoration: 'underline',
                      color: '#030671',
                      fontWeight: 600,
                      marginLeft: '8px'
                    }}
                  >
                    Read more
                  </Link>
                </Typography>
              </>
            }
            imageSrc="./images/background/Edit.webp" // Update image path
            imageAlt="Tiro Engineering Team"
          />
          <div id="Our Services"
            style={{ 
              paddingTop: '80px',  // Add padding equal to navbar height
              marginTop: '-80px',  // Offset the spacing
              scrollMarginTop: '5px' // New CSS property for scroll snapping
            }}
          >
            <Packages/>
          </div>
          <div>
            <WhatsAppButton 
              phoneNumber=" +277 2 566 0013"  // Replace with your WhatsApp number
              message="Hello! I have a question about your services."  // Custom default message
            />
          </div>
          <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px' }}>
              <Testimonials/>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px' }}>
            <MissionVisionSection/>
          </div>
          <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px' }}>
            <AutoPlayYouTube videoId="7Ndd6t2HrrA" /> 
          </div> 
            
      <Footer/>
          </div>
      </div>
   
  );
};
export default Home;
