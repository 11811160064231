// In Projects.js
import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import AnimatedPage from '../UI/AnimatedPage';
import ProjectsTable from '../UI/ProjectsTable';
import Footer from '../UI/footer';

const Projects = () => {
  return (
    <AnimatedPage>
      <Helmet>
        <title>Tiro Engineering - Projects</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        minHeight: '100vh',
        backgroundImage: 'url(/images/pack/dot2.PNG)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
        {/* Content Area */}
        <Box sx={{ 
          flexGrow: 1,
          py: 4,
          px: 2
        }}>
          <ProjectsTable />
        </Box>
        
        {/* Footer */}
        <Footer />
      </Box>
    </AnimatedPage>
  );
};
export default Projects;