import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Masonry from 'react-masonry-css';

const TextGallerySection = ({ 
  title, 
  description, 
  images, // Array of objects with src and alt properties
  backgroundColor = '#F5F5F5'
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [currentImage, setCurrentImage] = React.useState(null);

  const openImage = (image) => {
    setCurrentImage(image);
  };

  const closeImage = () => {
    setCurrentImage(null);
  };

  const navigateImages = (direction) => {
    const currentIndex = images.findIndex((image) => image.src === currentImage.src);
    const newIndex = (currentIndex + direction + images.length) % images.length;
    setCurrentImage(images[newIndex]);
  };

  const breakpointColumnsObj = {
    default: 2, // Increased from 3
    1100: 1,
    800: 2,
    500: 1
  };

  // Style for text with bold emphasis on specific parts
  const formattedDescription = () => {
    return (
      <>
        <Typography 
          variant="body1"
          sx={{
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.8,
            color: '#4A5568'
          }}
        >
          Tiro Engineering and Projects (Pty) Ltd is a turnkey <strong>Engineering Procurement and Construction (EPC)</strong> company specialising in high voltage substation projects. Founded in November 2014, the company was established to provide services to the electrical supply industry including but not limited to Utilities, Municipalities, Government, Mining houses and Private Sector. Currently based in, Johannesburg, our company's focus is on increasing our presence throughout Southern Africa and the rest of Africa.
        </Typography>
        <Typography 
          variant="body1"
          sx={{
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.8,
            color: '#4A5568',
            mt: 2
          }}
        >
          Tiro Engineering and Project Management is a <strong>100% black owned company</strong>, with the founders having more than 20 years experience in managing turnkey substation projects for organizations and municipalities such as City of Tshwane, Mogale City, Ekurhuleni Municipality, eThekwini Municipality, City of uMhlathuze, Kwadukuza Municipality, Lonmin Platinum Mine, Eskom enterprises, Lesotho Electricity Company (LEC), Botswana Power Corporation (BPC), Mondi Richards Bay, Polokwane Smelters, BHP Billiton and Letsheng Diamond Mine.
        </Typography>
      </>
    );
  };

  return (
    <Box sx={{ 
      py: { xs: 4, md: 8 },
      backgroundColor: backgroundColor,
      position: 'relative',
      // Add margin-top to prevent overlap with navbar
      mt: { xs: '64px', md: '72px' }, 
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'url(/images/pack/dot2.PNG)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0.3,
        zIndex: 1,
      },
      '& .masonry-grid': {
        display: 'flex',
        marginLeft: 0,
        width: '100%',
      },
      '& .masonry-grid-column': {
        paddingLeft: 8, // Reduced gutter
  backgroundClip: 'padding-box',
  '&:last-child': {
    paddingRight: 8 // Add right padding to last column
  }
      },
      '& .masonry-item': {
        margin: '0 0 20px 0', // Increased bottom margin
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.25)', // Enhanced shadow
        transition: 'transform 0.3s ease',
        cursor: 'pointer',
        height: 'auto', // Let height adjust to content
        '&:hover': {
          transform: 'scale(1.02)',
        },
        '& img': {
          width: '100%',
          height: 'auto',
          minHeight: '200px', // Minimum height for images
          objectFit: 'cover', // Maintain aspect ratio while filling
          display: 'block',
          borderRadius: '8px',
        }
      },
      '& .image-overlay': {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1200, // Higher z-index to ensure it's above other elements
      },
      '& .image-container': {
        position: 'relative',
        maxWidth: '90%',
        maxHeight: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .centered-image': {
        maxWidth: '100%',
        maxHeight: '90vh',
        objectFit: 'contain',
      },
      '& .close-button': {
        position: 'absolute',
        top: '0px', // Increased for better touch targets
        right: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        zIndex: 1001,
        padding: '1px',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }
      },
      '& .nav-button': {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        zIndex: 1001,
        padding: '10px',
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }
      },
      '& .leftBut': {
        left: '20px',
        composes: 'nav-button',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        cursor: 'pointer',
      },
      '& .rightBut': {
        right: '20px',
        composes: 'nav-button',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        border: 'none',
        cursor: 'pointer',
      }
    }}>
      <Container  
      maxWidth={false} // Remove max width constraint
      sx={{ 
        position: 'relative',
        zIndex: 2,
        px: { xs: 2, md: 4 } // Add horizontal padding instead of container padding
      }}>
        <Grid 
          container 
          spacing={{ xs: 4, md: 6 }}
          sx={{
            width: '100%',
            margin: 0 // Remove default margin
          }}
        >
          {/* Text Content */}
          <Grid item xs={12} md={5}>
            <Box sx={{ p: { xs: 2, md: 4 } }}>
              <Typography 
                variant="h3" 
                component="h2" 
                gutterBottom
                sx={{ 
                  color: "#000080",
                  fontWeight: 'bold',
                  fontSize: { xs: '2rem', md: '2.5rem' },
                  mb: 3
                }}
              >
                {title || "Who We Are"}
              </Typography>
              {description ? (
                <Typography 
                  variant="body1"
                  sx={{
                    fontSize: { xs: '1rem', md: '1.1rem' },
                    lineHeight: 1.8,
                    color: '#4A5568'
                  }}
                >
                  {description}
                </Typography>
              ) : (
                formattedDescription()
              )}
            </Box>
          </Grid>
          
          {/* Image Gallery */}
          <Grid item xs={12} md={7}>
            <Box sx={{ 
              mt: { xs: 2, md: 0 },
              width: '100%',
              pr: 0, // Remove right padding
              pb: 4,
              mx: 0 // Remove horizontal margin
            }}>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid"
                columnClassName="masonry-grid-column"
              >
                {images.map((image, index) => (
                  <div 
                    key={index} 
                    className="masonry-item"
                    onClick={() => openImage(image)}
                  >
                    <img src={image.src} alt={image.alt} />
                  </div>
                ))}
              </Masonry>
            </Box>
          </Grid>
        </Grid>
      </Container>
      
      {currentImage && (
        <div className="image-overlay">
          <div className="image-container">
            <button className="leftBut" onClick={() => navigateImages(-1)}>
              <ArrowBackIcon sx={{color:'white', fontSize: '2.5rem'}}/>
            </button>
            <img src={currentImage.src} alt={currentImage.alt} className="centered-image" />
            <button className="rightBut" onClick={() => navigateImages(1)}>
              <ArrowForwardIcon sx={{color:'white', fontSize: '2.5rem'}}/>
            </button>
            <button className="close-button" onClick={closeImage}>
              <CloseIcon sx={{color:'white', fontSize: '2.5rem'}}/>
            </button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default TextGallerySection;