import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Box,
  Link,
  Paper,
  Typography
} from '@mui/material';
import React from 'react';

const ContactUs = () => {
  return (
    <Box 
      sx={{ 
        position: 'relative',
        width: '100%',
        minHeight: 'calc(100vh - 180px)',
        backgroundImage: 'url(/images/background/c1.webp)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: { xs: 2, sm: 3 },
      }}
    >
      <Paper 
        elevation={6} 
        sx={{ 
          width: '100%',
          maxWidth: { xs: '100%', sm: 600 },
          p: { xs: 3, sm: 4 },
          borderRadius: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(5px)',
        }}
      >

        <Typography 
          variant="h4" 
          align="center" 
          sx={{ 
            fontSize: { xs: '2rem', sm: '2.5rem' },
            fontWeight: 'bold',
            color: '#000080',
            mb: 4
          }}
        >
          CONTACT DETAILS
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#000080',
              mb: 1,
              textAlign: 'center'
            }}
          >
            Samuel Makume
          </Typography>
          <Link 
            href="tel:+27725660013" 
            sx={{ 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
              color: '#2196f3',
              textDecoration: 'none',
              mb: 3,
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            <PhoneIcon sx={{ fontSize: 20 }} />
            +27 (0) 72 566 0013
          </Link>
        </Box>

        <Box 
          sx={{ 
            borderTop: '1px solid rgba(0,0,0,0.12)',
            pt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Link 
            href="tel:+27111007645"
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: '#000080',
              textDecoration: 'none',
              '&:hover': {
                color: '#2196f3'
              }
            }}
          >
            <PhoneIcon sx={{ fontSize: 20 }} />
            +27 (0) 11 100 7645
          </Link>

          <Link 
            href="mailto:info@tiroeng.co.za"
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: '#000080',
              textDecoration: 'none',
              '&:hover': {
                color: '#2196f3'
              }
            }}
          >
            <EmailIcon sx={{ fontSize: 20, }} />
            info@tiroeng.co.za
          </Link>

          <Link 
            href="https://maps.google.com/?q=63+Peter+Place,+Bryanston,+2191"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: '#000080',
              textDecoration: 'none',
              '&:hover': {
                color: '#2196f3'
              }
            }}
          >
            <LocationOnIcon sx={{ fontSize: 20 }} />
            63 Peter Place, Platform G7 Ground Floor Bryanston, 2191
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};

export default ContactUs;