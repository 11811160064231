import { Button, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';

// Create theme with custom colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#000080', // Navy Blue
    },
    secondary: {
      main: '#CD9B1D', // Gold
    }
  }
});

const CTAButton = ({ 
  variant = 'primary', 
  children, 
  onClick, 
  size = 'medium',
  fullWidth = false,
  ...props 
}) => {
  const buttonStyles = {
    primary: {
      backgroundColor: '#000080',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#CD9B1D',
      },
    },
    secondary: {
      backgroundColor: '#FFFFFF',
      color: '#000080',
      border: '2px solid #000080',
      '&:hover': {
        backgroundColor: '#CD9B1D',
        color: '#FFFFFF',
        border: '2px solid #CD9B1D',
      },
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={onClick}
        size={size}
        fullWidth={fullWidth}
        sx={{
          ...buttonStyles[variant],
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: '4px',
          padding: '8px 24px',
          fontSize: size === 'large' ? '1.1rem' : '1rem',
          transition: 'all 0.3s ease-in-out'
        }}
        {...props}
      >
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default CTAButton;